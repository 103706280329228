<template> 
  <div class="content">  
  
    <span class="text-info">{{valor}}</span>

  </div> 
</template>
 
<script>  
export default {
  name: 'ModalMostrarValorCheckIn', 
  props:{ 
      valor: null
  },

  data() {
    return {  

    }
  }

};

</script>
 
 <style scoped>

  .content {
    align-content: center;
  }


  span {
    padding: 30px;
    font-size: 50px;
    font-weight: 600;
  }

</style>