<template>
  <div id="page">
    <b-list-group>
      <div>
        <h2>Historícos</h2>

        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data do Histórico</label>
            <b-form-input
              type="date"
              id="datepicker-dateformat2"
              v-model="dataIn"
              locale="pt"
              @change="getUsuariosHistoricos"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Usuário</label>
            <b-form-select id='sel-mes' 
              v-model="selectedUsuario" 
              :options="usuarios" 
              @change="getHistoricos()">
            </b-form-select>
          </div>

        </div>

      </div>


      <div class="div-corpo">

        <div class="tabela">
          <b-table
            id="my-table"
            striped
            hover
            class="text-left"
            ref="selectableTable"
            :items="items"
            :fields="fields"
            :busy="load"
            small
            outlined
            @row-clicked="onSelect"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong></strong>
              </div>
            </template>


            <template #cell(descricao)="row">
              <span v-html="row.item.descricao"></span>
            </template>

            <!-- <template #cell(Apagar)="data">
              <img
                src="../assets/del4.png"
                alt="apagar"
                height="23px"
                @click="showModalApagar(data.item)"
              />
            </template> -->


          </b-table>
        </div>
      

      </div>


    </b-list-group>





    <!-- MODAL DELETAR -->
    <!-- <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover a ocorrência?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div> -->
    <!-- MODAL DELETAR FIM-->



  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from "../services/api";

export default {
  name: "Periodos",

  data() {
    return {

      colonia: null,
      dataIn: null,
      selectedUsuario: 'Todos',
      usuarios: [],

      form: {
        titulo: null,
        descricao: null,
        userCreate: null,
        dataCreate: null
      },

      fields: [
        { key: "cod", label: "Cód." },
        { key: "descricao", label: "Descrição" },
        { key: "userCreate", label: "Usuário" },
        { key: "dataCreate", label: "Data", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY HH:mm') : null
        }},
        // { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      load: false,

    };
  },



  created() {

    this.dataIn = dayjs().format('YYYY-MM-DD')
    this.colonia = this.$store.state.colonia
    this.getUsuariosHistoricos()
    
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    onSelect(item) {
      // console.log(item, index)
      this.textComentario = item.descricao
    },

    

    getUsuariosHistoricos() {

      this.load = true;

      api.get(`historicos-usuarios/${this.colonia}/${this.dataIn}`)
      .then((res) => {
        this.load = false
        this.usuarios = res.data

        this.getHistoricos()
        this.selectedUsuario = 'Todos'
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },

    getHistoricos() {

      this.load = true;

      api.get(`historicos/${this.colonia}/${this.dataIn}/${this.selectedUsuario}`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   
    



  },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */


.div-corpo{
  height: 62vh;
  overflow: scroll;
  cursor: pointer;
}

.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
}

#page h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

/* .btnRight {
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  font-weight: bold;
} */

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>