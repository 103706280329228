<template>
  <div id="page">
    <b-list-group>
      <div>
        <h2>Períodos</h2>

          <b-row align-h="between">
              
              <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
              <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->

              <div class="form-group col-lg-4">
                <label><span class="text-danger"></span> Buscar</label>
                <input class="form-control"  v-model="filtro" />
              </div>

          </b-row>
      </div>


      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>

          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>


        </b-table>
      </div>
    </b-list-group>




    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Períodos" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-6">
            <label><span class="text-danger">*</span> Nome do período</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
              maxlength="100"
            ></b-form-input>
          </div>


          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data inicio</label>
            <b-form-input
              type="date"
              id="datepiker"
              v-model="form.dataInicio"
              locale="pt"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <label><span class="text-danger"></span> Data final</label>
            <b-form-input
              type="date"
              id="datepiker"
              v-model="form.dataFinal"
              locale="pt"
            ></b-form-input>
          </div>          

        </div>

        <b-container class="bv-example-row">
          <b-row align-h="start">
            <b-button class="mt-3 botao-confirmar2" variant="success" @click="save">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar2" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
          </b-row>

          <!-- <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
          </b-row> -->
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL CADASTRO FIM -->




    <!-- MODAL DELETAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover o período?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL DELETAR FIM-->



  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from "../services/api";
import { acessoRestrito } from "@/global";

export default {
  name: "Periodos",

  data() {
    return {

      filtro: '',

      form: {
        nome: null,
        dataInicio: null,
        dataFinal: null
      },

      fields: [
        { key: "nome", label: "Nome período (opcional)" },
        { key: "dataInicio", label: "Data inicio", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: "dataFinal", label: "Data final", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      load: false,

    };
  },



  created() {
    this.getPeriodos();
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },



    getPeriodos() {
      acessoRestrito(20)

      this.load = true;

      api.get(`periodos`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   


    showModalAdd() {
      acessoRestrito(21)
      this.resetForm()
      this.$refs["modal-add"].show()
    },

    save() {

      api.post("periodos", this.form)
      .then(res => {

        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getPeriodos()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },


    Editar(item) {
      acessoRestrito(22)
      this.form = {...item}
      this.$refs["modal-add"].show()
    },
    

    showModalApagar(item) {
      acessoRestrito(23)
      this.$refs["modal-del"].show();
      this.form = item;
    },

    apagar() {
      this.$refs["modal-del"].hide();

      api.delete(`periodos/${this.form.id}`)
      .then(() => {
        this.msgNotification('Período removido.', 'success', 5000)
        this.getPeriodos()
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000));
        
    },




     resetForm() {

      const form = this.form
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



  },

  computed: {

      filtrar() {

          // console.log('opaa')
          
          if (this.filtro.trim() == '') return this.items
              
          let listaFiltrada = []
          this.items.forEach((item) => {
              if(item.nome && item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 

              const dataInicio = dayjs(item.dataInicio).format('DD/MM/YYYY')
              if(dataInicio.match(this.filtro)) listaFiltrada.push(item) 

              const dataFinal = dayjs(item.dataFinal).format('DD/MM/YYYY')
              if(dataFinal.match(this.filtro)) listaFiltrada.push(item) 
          })

          return listaFiltrada
          // return this.items
      },
  },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */



.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
}

#page h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

/* .btnRight {
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  font-weight: bold;
} */

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>