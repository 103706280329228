<template>
  <div id="page">


    <div class="row text-left col-lg-12">

      <div class="form-group col-lg-6">
        <label><span class="text-danger"></span>Produto</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="produto.nome"
          readonly
        ></b-form-input>
      </div> 

      <div class="form-group col-lg-3">
        <label><span class="text-danger"></span>Quantidade</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="produto.quantidade"
          readonly
        ></b-form-input>
      </div>
       
      <div class="form-group col-lg-3" v-if="loadSwith && acessoPermitido">
        <label><span class="text-danger"></span>Controlar o estoque?</label>
        <b-form-checkbox switch size="lg" v-model="produto.controlarEstoque" @change="mudarControleEstoque"></b-form-checkbox>
      </div> 

    </div>

    
    
    <div class="divider"></div>



    <b-list-group v-if="produto.controlarEstoque">
      <div class="row text-left col-lg-12">


        <div class="form-group col-lg-6" style="margin-top: -6px; margin-bottom: 15px;">
          <b-form-group label="Adicionar quantidade" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
              <input  
                id="input-adicionar"
                class="form-control" 
                v-model="qtdLancamento" 
                @keyup="teclaEnter($event)"
              />
              <b-input-group-append is-text class="text-monospace cursor" @click="abrirModalNF">
                <b-icon icon="plus-circle-fill" variant="success" class="cursor"></b-icon>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        

        <div class="form-group col-lg-6" style="margin-top: -6px; margin-bottom: 15px;">
          <b-form-group label="Remover quantidade" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
            <b-input-group>
              <input  
                id="input-remover"
                class="form-control" 
                v-model="qtdRemover" 
                @keyup="teclaEnter($event)"
              />
              <b-input-group-append is-text class="text-monospace cursor" @click="removerQuantidade">
                <b-icon icon="patch-minus-fill" variant="danger" class="cursor"></b-icon>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        
      </div>    
    </b-list-group>



    <!-- <div class="divider"></div> -->


    
    <div>
      <b-table
        id="my-table"
        striped
        hover
        class="text-left"
        ref="selectableTable"
        :items="estoques"
        :fields="fieldsEstoques"
        :busy="load"
        small
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong></strong>
          </div>
        </template>


        <template #cell(Tipo)="data">
          <b-icon class="cursor" :icon="data.item.icon" :variant="data.item.iconColor" style="width: 18px; height: 18px;" @click="validarBaixa(data.item)"></b-icon>
        </template>

        <template #cell(Apagar)="data">
          <img v-if="data.item.status == 'adicionado' || data.item.status == 'removido'"
            src="../../../assets/del4.png"
            alt="apagar"
            height="23px"
            @click="showModalApagar(data.item)"
          />
        </template>

      </b-table>
    </div>

    


    <!-- MODAL NF -->
    <div>
      <b-modal ref="modal-NF" hide-footer title="">
        <div class="d-block text-center">
          <h4>Informe o Nº da Nota Fiscal ou Recibo</h4>
          <input  
            id="input-nf"
            class="form-control" 
            v-model="notaFiscal" 
            @keyup="teclaEnter($event)"
          />
        </div>
        <b-button class="mt-3" variant="success" block @click="adicionaQuantidade">Confirmar</b-button>
      </b-modal>
    </div>
    <!-- MODAL NF FIM-->



    <!-- MODAL DELETAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover este lançamento?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar">Sim</b-button>
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL DELETAR FIM-->


  </div>
</template>




<script>
import dayjs from 'dayjs'
import api from "../../../services/api";
import { acessoRestrito } from "@/global";

export default {
  name: "ModalEstoque",
  props: {
    produto: {}
  },

  data() {
    return {

      qtdLancamento: null,
      qtdRemover: null,
      notaFiscal: null,

      form: {
        id: null,
        idProduto: null
      },

      estoques: [],
      fieldsEstoques: [
        { key: "Tipo", label: "" },
        { key: "quantidade", label: "Quantidade" },
        { key: "notaFiscal", label: "Nota Fiscal" },
        { key: "dataCreate", label: "Data", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY HH:mm') : null
        }},
        { key: "userCreate", label: "Usuário" },
        { key: "Apagar", label: "" },
      ],

      load: false,
      loadSwith: true,
      acessoPermitido: false

    };
  },



  created() {
    // console.log('produto',this.produto)
    this.acessoPermitido = acessoRestrito(40, false)
    this.getEstoque()
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    teclaEnter(event) {
      // console.log(event)
      // console.log(event.target.id)

      const inputID = event.target.id

      if (event.keyCode == 13 && inputID == 'input-adicionar')
      this.abrirModalNF()

      if (event.keyCode == 13 && inputID == 'input-remover')
      this.removerQuantidade()
    },


    getEstoque() {
      this.load = true;

      api.get(`estoque/${this.produto.id}`)
      .then((res) => {
        this.load = false
        this.estoques = res.data
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },   

    mudarControleEstoque() {
      acessoRestrito(40)

      this.loadSwith = false

      const status = this.produto.controlarEstoque ? 'ativado' : 'desativado'

      api.put(`estoque/mudar-controle-estoque`, { idProduto: this.produto.id, controlarEstoque: status })
      .then(() => {

        this.loadSwith = true
        this.msgNotification(`Controle de estoque ${status}`, 'success', 3000)
        this.getEstoque()
        this.EMIT_atualizaProduto()
      })
      .catch(() => {
        this.loadSwith = true
        this.msgNotification('Falha ao mudar o controle de estoque', 'error', 5000)
        this.produto.controlarEstoque = !this.produto.controlarEstoque
      });
    },

    EMIT_atualizaProduto() {

      const dados = {
        id: this.produto.id,
        controlarEstoque: this.produto.controlarEstoque,
        quantidade: this.produto.quantidade
      }
      this.$emit('EMIT_Produtos_atualizaProduto', dados)
    },


    abrirModalNF() {

      acessoRestrito(41)
      
      if (!this.qtdLancamento || this.qtdLancamento < 1) {
        this.msgNotification('Informe a quantidade correta.', 'warning', 5000)
        return
      }

      this.$refs["modal-NF"].show()
    },

    adicionaQuantidade() {

      // acessoRestrito(41)
      
      // if (!this.qtdLancamento || this.qtdLancamento < 1) {
      //   this.msgNotification('Informe a quantidade correta.', 'warning', 5000)
      //   return
      // }

      if (!this.notaFiscal || this.notaFiscal.trim() == '') {
        this.msgNotification('Informe o número da nota fiscal.', 'warning', 5000)
        return
      }

      this.$refs["modal-NF"].hide()


      const dados = {
        idProduto: this.produto.id,
        quantidade: this.qtdLancamento,
        notaFiscal: this.notaFiscal
      }

      api.post(`estoque/adiciona`, dados)
      .then(() => {

        this.qtdLancamento = null
        this.msgNotification('Estoque adicionado.', 'success', 5000)
        this.getEstoque()
        this.produto.quantidade = Number(this.produto.quantidade) + Number(dados.quantidade)
        this.EMIT_atualizaProduto()
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });
      
    },

    removerQuantidade() {

      acessoRestrito(42)
      
      if (!this.qtdRemover || this.qtdRemover < 1) {
        this.msgNotification('Informe a quantidade correta.', 'warning', 5000)
        return
      }


      const dados = {
        idProduto: this.produto.id,
        quantidade: this.qtdRemover
      }

      api.post(`estoque/remove`, dados)
      .then(() => {

        this.qtdRemover = null
        this.msgNotification('Estoque removido.', 'success', 5000)
        this.getEstoque()
        this.produto.quantidade = Number(this.produto.quantidade) - Number(dados.quantidade)
        this.EMIT_atualizaProduto()
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });
      
    },


    showModalApagar(item) {
      acessoRestrito(43)

      this.$refs["modal-del"].show()
      this.form = item
    },

    apagar() {
      
      this.$refs["modal-del"].hide()

      api.delete(`estoque/${this.form.id}/${this.form.idProduto}`)
      .then(() => {
        
        this.produto.quantidade = this.form.status == 'adicionado' 
        ? Number(this.produto.quantidade) - Number(this.form.quantidade)
        : Number(this.produto.quantidade) + Number(this.form.quantidade)
        
        this.msgNotification('Lançamento removido.', 'success', 5000)
        this.getEstoque()
        this.EMIT_atualizaProduto()
      })
      .catch((err) => this.msgNotification(err.response.data, 'warning', 5000));
    },
    
  
  },
  
};

</script>

<style scoped>

.botao-avulso{
  margin-top: 20px;
}

.botao-confirmar {
    width: 45%; 
    margin-left: 10px;
}

img, .cursor {
  cursor: pointer;
}


.divider {
  margin-top: 25px;
  margin-bottom: 25px;
  height: 1px;
  width: 100%;
  background-color: #cfcfcf;
}

</style>