<template>

  <div class="content"> 
  

    <div class="row text-left col-lg-12">

      <div class="form-group col-lg-12">
        <span class="text-info content">Valor à receber: {{valorRestanteAReceberView}}</span>
      </div>
      

      <div class="form-group col-lg-6">
        <b-button class="button-aplicar" variant="success" block @click="confirmar">Receber e imprimir recibo</b-button>
      </div>

      <div class="form-group col-lg-6">
        <b-button class="button-aplicar" variant="danger" block @click="cancelar">Cancelar</b-button>
      </div>

    </div>

    
  </div>
</template>

<script>
import dayjs from 'dayjs';
import extenso from 'extenso'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'


export default {
  name: "ModalValorAReceberPosCheckIn",
  props: {
    reserva: {},
    valorRestante: null
  },

  data() {
    return {

      load: false,

      user: {},

      valorRestanteAReceber: 0,
      valorRestanteAReceberView: 0,

      valorExtenso: null,
      valor: null,
      dataHoje: null,
      porcentagem: null,
      userName: null,


      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],


    }
  },


  created() {

    // const colonia = this.$store.state.colonia 
    this.calculaValorRestante()


    this.user = JSON.parse(localStorage.getItem('user'))
    this.userName = this.user.nome



    const dia = dayjs().format('DD')
    const mes = dayjs().format('MM')
    const mesTexto = this.meses.filter(item => item.value == mes)[0].text
    const ano = dayjs().format('YYYY')
    this.dataHoje = `${this.user.sede}, ${dia} de ${mesTexto} de ${ano}`
    
    const valorRecibo = this.valorRestanteAReceber

    this.valor = valorRecibo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

    this.valorExtenso = extenso(parseFloat(valorRecibo), { mode: 'currency', currency: { type: 'BRL' } })

    this.porcentagem = Math.trunc((valorRecibo / this.reserva.valorTotal) * 100)
  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    calculaValorRestante() {

      // const hospedesNovos = this.reserva.hospedes.filter(item => !item.id)
      this.valorRestanteAReceber = this.valorRestante
      this.valorRestanteAReceberView = this.valorRestanteAReceber.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      })
    },

    confirmar() {
      this.gerarPdf()
      this.$emit('EMIT_Reserva_updateReserva')
    },

    cancelar() {
      this.$emit('EMIT_fechar_modal')
    },



    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {
      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 27, 27) 

      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text('Sede Central - Osasco - SP', width/2, 28, { align: 'center' })
      
      doc.setFontSize(8).setFont(undefined, 'bold'); 
      doc.text('PRAÇA PADROEIRA DO BRASIL, 127 - CEP: 06010-090 - JD. AGÚ - OSASCO - FONE/FAX: 3682-9303', width/2, 34, { align: 'center' })

      doc.setDrawColor(0, 0, 0);
      doc.line(0, 36, 500, 36);
      //------------------------------------------------------------------




      doc.setFontSize(15);          
      doc.text('RECIBO', width/2, 50, { align: 'center' })

      doc.setFontSize(10);          
      doc.text(`Reserva ${this.reserva.cod}`, 180, 50)

      doc.text(this.valor, 198, 56, {maxWidth: 25, align: 'right'});

      doc.text(`RECEBI DE ${this.reserva.nome},  PORTADOR DO CPF: ${this.reserva.cpf}`, 14, 80)
      // doc.text('CPF: 329.207.778-82', 166, 80, {maxWidth: 40, align: 'justify'});

      doc.text(`A IMPORTANCIA DE (${this.valorExtenso.toUpperCase()})`, 14, 88)
      doc.text(`REFERÊNTE AO PAGAMENTO de alteração na data da reserva ou hóspedes que chegaram após o check-in.`, 14, 96)


      doc.text(this.dataHoje, 28, 140)
      doc.text('_______________________________________', 110, 140)
      doc.text(this.userName, 150, 146, {maxWidth: 60, align: 'center'});
      //------------------------------------------------------------------
                              



      doc.save(`Recibo-pós-checkin nº ${this.reserva.cod}.pdf`)
    },



  }


}

</script>

<style scoped>

.content {
  align-content: center;
}


span {
  padding: 30px;
  font-size: 50px;
  font-weight: 600;
}


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>