<template> 
  <div>  
    


    <b-col sm="12">
      <b-form-group label-cols="12" label-cols-lg="12" label="">
        <div>
          <b-form-textarea 
          v-model="textComentario"
          rows="10"
          ></b-form-textarea>
        </div>           
      </b-form-group>   
    </b-col>



    <b-container class="bv-example-row">
      <b-row align-h="start">
        <b-button class="mt-3 botao-confirmar2" variant="success" @click="confirmar()">Confirmar</b-button>
        <b-button class="mt-3 botao-confirmar2" variant="danger" @click="cancelar()">Cancelar</b-button>
      </b-row>

      <!-- <b-row align-h="around">
        <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
        <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
      </b-row> -->
    </b-container>


  </div> 
</template>
 
<script>
// import { acessoRestrito } from "@/global";
// import api from "@/services/api";
  
export default {
  name: 'ModalCheckOutAntecipado', 
  props:{ 
      reserva: {}
  },

  data() {
    return {  

      load: false,
      textComentario: '',

    }

  },
  created() { 

      // dw
  },
  
  methods: {

    msgNotification(texto, tipo, tempo) {
        this.$toast.open({
            message: texto,
            type: tipo,
            position: 'top-right',
            duration: tempo
            // all of other options may go here
        })
    },



    confirmar() {

      // console.log('this.textComentario.length', this.textComentario.length)
      if (this.textComentario.length < 5) {
        this.msgNotification('Digite o motivo do check-out antecipado.', 'warning', 5000)
        return
      }

      this.reserva.checkoutAntecipadoJustificado = true
      const authLocal = JSON.parse(localStorage.getItem('user'))
      const userName = authLocal.nome

      const comentario = {
        idReserva: this.reserva.id,
        comentario: 'MOTIVO DO CHECKOUT ANTECIPADO • '+this.textComentario,
        privacidade: 'Público',
        userCreate: userName
      }

      // console.log('comentario', comentario)
      this.$emit('EMITjustificaCheckoutAntecipado_confirmar', comentario)
    },

    cancelar() {

      this.$emit('EMITjustificaCheckoutAntecipado_cancelar')
    }

  }

    
};

</script>
 
   
 <style scoped>


 .tabela {
    width: 80vw;
    margin-top: 0px;
  }


  .botao-confirmar {
    width: 45%; 
    /* width: 170px;  */
    margin-left: 10px;
  }


  .botao-confirmar2 {
    width: 170px; 
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .cursor { 
    cursor: pointer;
  }

</style>