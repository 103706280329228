<template>
  <div id="page">
    <b-list-group>
      <div>
        <h2>Ocorrências</h2>

          <b-row align-h="between">
              
              <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
              <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->

              <div class="form-group col-lg-4">
                  <label><span class="text-danger"></span> Buscar</label>
                  <input class="form-control"  v-model="filtro" />
              </div>

          </b-row>
      </div>


      <div class="div-corpo">

        <div class="tabela">
          <b-table
            id="my-table"
            striped
            hover
            class="text-left"
            ref="selectableTable"
            :items="filtrar"
            :fields="fields"
            :busy="load"
            small
            outlined
            @row-clicked="onSelect"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong></strong>
              </div>
            </template>


            <template #cell(Editar)="data">
              <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
              <img
                src="../assets/edit1.png"
                alt="editar"
                height="23px"
                @click="Editar(data.item)"
              />
            </template>
            <template #cell(Apagar)="data">
              <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
              <img
                src="../assets/del4.png"
                alt="apagar"
                height="23px"
                @click="showModalApagar(data.item)"
              />
            </template>


          </b-table>
        </div>
      

      </div>


      <b-col sm="12">
        <b-form-group label-cols="12" label-cols-lg="12" label="">
          <div>
              <b-form-textarea 
              v-model="textComentario"
              rows="3"
              readonly
              ></b-form-textarea>
          </div>           
        </b-form-group>   
      </b-col>
    </b-list-group>




    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Ocorrências" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-12">
            <label><span class="text-danger">*</span> Título</label>
            <b-form-input
              class="text-uppercase"
              v-model="form.titulo"
              onkeyup="return lowerCase(this)"
              maxlength="100"
            ></b-form-input>
          </div>


          <b-col sm="12">
          <b-form-group label-cols="12" label-cols-lg="12" label="Descrição">
            <div>
                <b-form-textarea 
                v-model="form.descricao"
                rows="3"
                ></b-form-textarea>
            </div>           
          </b-form-group>   
        </b-col>         

        </div>

        <b-container class="bv-example-row">
          <b-row align-h="start">
            <b-button class="mt-3 botao-confirmar2" variant="success" @click="save">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar2" variant="danger" @click="$refs['modal-add'].hide()">Cancelar</b-button>
          </b-row>

          <!-- <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar" variant="success" @click="validaHospede">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-add-hospede'].hide()">Cancelar</b-button>
          </b-row> -->
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL CADASTRO FIM -->




    <!-- MODAL DELETAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Deseja remover a ocorrência?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL DELETAR FIM-->



  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from "../services/api";
import { acessoRestrito } from "@/global";

export default {
  name: "Periodos",

  data() {
    return {

      colonia: null,
      filtro: '',

      textComentario: '',

      form: {
        titulo: null,
        descricao: null,
        userCreate: null,
        dataCreate: null
      },

      fields: [
        { key: "id", label: "Ocorrência" },
        { key: "titulo", label: "Título" },
        { key: "userCreate", label: "Usuário" },
        { key: "dataCreate", label: "Data", formatter: value => {
          return  value ? dayjs(value).format('DD/MM/YYYY') : null
        }},
        { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 

      load: false,

    };
  },



  created() {
    this.colonia = this.$store.state.colonia
    this.getOcorrencias();
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    onSelect(item) {
      // console.log(item, index)
      this.textComentario = item.descricao
    },

    


    getOcorrencias() {
      acessoRestrito(30)

      this.load = true;

      api.get(`ocorrencias/${this.colonia}`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      });

    },   


    showModalAdd() {
      acessoRestrito(31)
      this.resetForm()
      this.$refs["modal-add"].show()
    },

    save() {

      this.form.colonia = this.colonia
      
      api.post("ocorrencias", this.form)
      .then(res => {

        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.getOcorrencias()
        this.resetForm()

      })
      .catch((err) => {
        // this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },


    Editar(item) {
      acessoRestrito(32)
      this.form = {...item}
      this.$refs["modal-add"].show()
    },
    

    showModalApagar(item) {
      acessoRestrito(33)
      this.$refs["modal-del"].show();
      this.form = item;
    },

    apagar() {
      this.$refs["modal-del"].hide();

      api.delete(`ocorrencias/${this.form.id}`)
      .then(() => {
        this.msgNotification('Ocorrência removida.', 'success', 5000)
        this.getOcorrencias()
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000));
        
    },




     resetForm() {

      const form = this.form
      
      for (var proper in form){
        
        form[proper] = null
      }
      
    },



  },

  computed: {

      filtrar() {

          // console.log('opaa')
          
          if (this.filtro.trim() == '') return this.items
              
          let listaFiltrada = []
          this.items.forEach((item) => {
              if(item.titulo.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
              if(item.userCreate.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
              if(item.dataCreateView.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
              // if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
          })

          return listaFiltrada
          // return this.items
      },
  },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */


.div-corpo{
  height: 48vh;
  overflow: scroll;
  cursor: pointer;
}

.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
}

#page h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

/* .btnRight {
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  font-weight: bold;
} */

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

    

}
</style>