<template>

  <div id="page-empresas">

    <b-list-group>
      <div>
        <h2>Bar - Relatórios</h2>

          <b-row align-h="between">              

              <div class="form-group col-lg-4">
                  
                <date-picker  
                  id="input-periodo"
                  class="data-picker"
                  v-model="dataFiltro"
                  lang="pt-br" 
                  range
                  style="width:260px;"
                  type="date"
                  format="DD/MM/YYYY"
                  @change="getRelatoriosBar"> 
                </date-picker>

              </div>

              <button class="button-imprimir" @click="imprimir">Imprimir</button>
              <!-- <BotaoRelatorioBar :dados="{ dataFiltro, produtos: items}"></BotaoRelatorioBar> -->

          </b-row>
      </div>


      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="items"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>


        </b-table>
      </div>
    </b-list-group>



    <!-- MODAL AGUARDE -->
    <div>
      <b-modal ref="modal-aguarde" hide-footer title="Gerando relatório, aguarde..." centered size="mx">

        <GerarPdf :dados="{ dataFiltro, produtos: items}"></GerarPdf> 

      </b-modal>
    </div>
    <!-- MODAL AGUARDE FIM -->


  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import dayjs from "dayjs"
import api from "../../../services/api";
import GerarPdf from './GerarPdf'
// import ModalAguarde from './ModalAguarde.vue'
// import { acessoRestrito } from "@/global";

export default {
  name: "EmpresasConvenio",
  components: { 
    DatePicker,
    GerarPdf
  },

  data() {
    return {

      filtro: '',
      dataFiltro: [],

      fields: [
        { key: "produto", label: "Produto" },
        { key: "quantidade", label: "Quantidade" },
        { key: 'valor', label: 'Valor unidade', formatter: value => {
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right'},
        { key: 'valorTotal', label: 'Valor total', formatter: value => {
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }, thClass: 'text-right', tdClass: 'text-right'}
      ],

      items: [], 
      load: false

    };
  },



  created() {

    // console.log('oioippp')

    this.dataFiltro[0] = new Date(dayjs())
    this.dataFiltro[1] = new Date(dayjs())

    this.getRelatoriosBar()
  },

  methods: {


    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    

    getRelatoriosBar() {

      this.load = true;

      const dataIn = dayjs(this.dataFiltro[0]).format('YYYY-MM-DD')
      const dataOut = dayjs(this.dataFiltro[1]).format('YYYY-MM-DD')

      api.get(`bar/relatorios/${dataIn}/${dataOut}`)
      .then((res) => {
        this.load = false
        this.items = res.data
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'warning', 5000)
      });

    },   
    

    async imprimir() {

      if (this.dataFiltro[0] == null || this.dataFiltro[1] == null) {
        this.msgNotification('Informe o período', 'info', 5000)
        return
      }

      if (this.items.length == 0) {
        this.msgNotification('Não existe fluxo de produtos neste período.', 'info', 5000)
        return
      }

      this.$refs["modal-aguarde"].show()
      await new Promise(r => setTimeout(r, 2000))
      this.$refs["modal-aguarde"].hide()
    }



  }

  
};
</script>

<style scoped>


.button-imprimir {
  margin-bottom: 20px;
  margin-right: 15px;
  height: 40px;
  width: 210px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}


#page-empresas h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

@media (max-width: 700px) {

  h2 {
    margin-left: 15px;
    font-size: 22px;
    font-weight: bold;
  }

  .tabela {
    margin-left: 15px;
    width: 310px;
    overflow: auto;
  }

  .btnRight {
    height: 40px;
    width: 90%;
    margin-top: 33px;
    margin-left: 15px;
    font-weight: bold;
  }  

}
</style>