function validaCelular(celular) {

    // console.log('celular',celular)
    
    if (!celular) return false

    for (let i = 0; i < celular.length; i++) {
        const letra = celular[i]
        // console.log(i, letra)
        // console.log('oi',letra.match(/[0-9]+/))

        if (i == 0 && letra != '(') return false
        if (i == 1 && !letra.match(/[0-9]+/)) return false
        if (i == 2 && !letra.match(/[0-9]+/)) return false
        if (i == 3 && letra != ')') return false
        if (i == 4 && letra != ' ') return false
        if (i == 5 && letra != '9') return false
        if (i == 6 && !letra.match(/[0-9]+/)) return false
        if (i == 7 && !letra.match(/[0-9]+/)) return false
        if (i == 8 && !letra.match(/[0-9]+/)) return false
        if (i == 9 && !letra.match(/[0-9]+/)) return false
        if (i == 10 && letra != '-') return false
        if (i == 11 && !letra.match(/[0-9]+/)) return false
        if (i == 12 && !letra.match(/[0-9]+/)) return false
        if (i == 13 && !letra.match(/[0-9]+/)) return false
        if (i == 14 && !letra.match(/[0-9]+/)) return false
    }

    if (celular.length != 15) return false

    return true
}


module.exports = { validaCelular }