<template>
  <div>
  

    <b-button class="button-aplicar" variant="success" block @click="gerarPdf()">Mapa da Colônia</b-button>  


  </div>
</template>



<script>
import { acessoRestrito } from "@/global";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";

export default {
  name: "BotaoMapaColonia",


  data() {
    return {

      load: false,

    }
  },


  created() {

    // const colonia = this.$store.state.colonia 


  },
  

  methods: {



  
    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.jpg$/)
        return images('./' + nomeFile + ".jpg")
    },


    async gerarPdf() {

      acessoRestrito(252)

      
      var doc = jsPDF('p', 'mm', [210, 297]);  


      var img = new Image()
      img.src = this.getImgUrl('Mapa') 
      doc.addImage(img, 'jpg', 0, 0, 210, 297) 


      doc.save('Mapa Colônia.pdf')
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>