<template>
  <div>
    <b-navbar toggleable="lg" type="dark"  class="topo">
        
        <img src="../assets/Home.png" alt="Dashboard" @click="toDashboard">
        <b-navbar-brand class="nome-empresa" href="#" @click="toDashboard">Colônia </b-navbar-brand>
        <!-- <b-navbar-brand class="nome-empresa" to="/dashboard" href="#">Finances Plus</b-navbar-brand> -->
        <!-- <router-link to="/dashboard" exact class="nome-empresa">EventoSP</router-link> -->

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>      


            <b-nav-item-dropdown :text="`${coloniaNome}`" class="menu-colonia active item-drop" right>
                <b-dropdown-item href="#" @click="setColonia(colonia)" v-for="colonia in colonias" :key="colonia">{{colonia}}</b-dropdown-item>
            </b-nav-item-dropdown>


            <b-nav-item-dropdown text="Gerenciar" class="item-drop" :class="{active: true}" right v-if="menuVisivel && acessoMenuGerenciar">
                <!-- <router-link to="/criar-escala-1" tag="b-dropdown-item" exact>Nova Escala</router-link> -->
                <b-dropdown-item @click="toQuartos()">
                    <b-icon icon="house-fill" aria-hidden="true"></b-icon>
                    Quartos
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toEmpresasConvenio()">    
                    <b-icon icon="exclude" aria-hidden="true"></b-icon>
                    Empresas Convêniadas
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toPeriodos()">
                    <b-icon icon="calendar" aria-hidden="true"></b-icon>
                    Períodos
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toOcorrencias()">
                    <b-icon icon="exclamation-diamond-fill" aria-hidden="true"></b-icon>
                    Ocorrências
                </b-dropdown-item>
                <b-dropdown-divider v-if="acessoHistoricos"></b-dropdown-divider>
                <b-dropdown-item @click="toHistoricos()" v-if="acessoHistoricos">
                    <b-icon icon="clock-fill" aria-hidden="true"></b-icon>
                    Históricos
                </b-dropdown-item>
            </b-nav-item-dropdown>  


            <b-nav-item-dropdown text="Bar" class="item-drop" :class="{active: true}" right v-if="menuVisivel && coloniaNome == 'Chico Amaro' && acessoMenuBar">
                <b-dropdown-item @click="toCreditosCartoes()">
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                    Créditos e Cartões
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toProdutos()">
                    <b-icon icon="box" aria-hidden="true"></b-icon>
                    Produtos
                </b-dropdown-item>
                <b-dropdown-divider v-if="acessoBarRelatorios"></b-dropdown-divider>
                <b-dropdown-item  v-if="acessoBarRelatorios" @click="toRelatoriosBar()">
                    <b-icon icon="printer" aria-hidden="true"></b-icon>
                    Relatórios
                </b-dropdown-item>
            </b-nav-item-dropdown>  


            <b-nav-item-dropdown text="Reservas" class="item-drop" :class="{active: true}" right v-if="menuVisivel">
                <b-dropdown-item @click="toReservas()">
                    <b-icon icon="building" aria-hidden="true"></b-icon>
                    Gerenciar Reservas
                </b-dropdown-item>
                 <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toConsultarSocio()">
                    <b-icon icon="person-bounding-box" aria-hidden="true"></b-icon>
                    Consultar Sócio
                </b-dropdown-item>
                 <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="toConsultarDisponibilidade()">
                    <b-icon icon="calendar4-week" aria-hidden="true"></b-icon>
                    Consultar Disponibilidades
                </b-dropdown-item>
                <b-dropdown-divider v-if="opcaoAtualizarSociosMensalidadesShow"></b-dropdown-divider>
                <b-dropdown-item @click="toAtualizaSociosMensalidades()"  v-if="opcaoAtualizarSociosMensalidadesShow">
                    <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                    Atualizar sócios e mensalidades
                </b-dropdown-item>
            </b-nav-item-dropdown> 

        </b-navbar-nav>



        


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

            <div class="notificacoes" @click="toExibirAvisos">
                <div class="balao-notificacao">
                    <b-badge class="quantia-notificacao" variant="info" v-if="countAvisos">{{countAvisos}}</b-badge>
                    <b-icon icon="bell-fill" class="icone" variant="none"></b-icon>
                </div>
            </div>

            <b-nav-item-dropdown class="active" right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                <em>{{userName}}</em>
                </template>
                <b-dropdown-item @click="gerenciarUsuarios" v-if="menusAdminsShow">Gerenciar Usuários</b-dropdown-item>
                <b-dropdown-item @click="alterarSenha">Alterar Senha</b-dropdown-item>
                <b-dropdown-item @click="toAjuda">Ajuda</b-dropdown-item>
                <b-dropdown-item href="#" @click="signOut">Sair</b-dropdown-item>
            </b-nav-item-dropdown>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>



  </div>
</template>

<script>
import api from "@/services/api";
import { acessoRestrito } from "@/global";

export default {
    data(){
        return{

            acessoHistoricos: false,
            acessoMenuGerenciar: false,
            acessoMenuBar: false,
            menusAdminsShow: true,
            userName: '',
            menuVisivel: false,
            opcaoAtualizarSociosMensalidadesShow: false,
            acessoBarRelatorios: false,

            colonias: [],
            coloniaNome: 'Selecione a colônia',

            
            countAvisos: 0,
            avisos: []

        }
    },

    created() {  

        const user = this.$store.state.auth.user 
        this.acessoMenuGerenciar = user.userLocal == 'Sede' ? false : true
        this.acessoMenuBar = user.userLocal == 'Sede' ? false : true

        this.acessoHistoricos = acessoRestrito(50, false)
        this.acessoBarRelatorios = acessoRestrito(120, false)

        this.opcaoAtualizarSociosMensalidadesShow = acessoRestrito(510, false)

        this.menusAdminsShow = acessoRestrito(1000, false)
        this.getColonias()
        this.getAvisos()

        setInterval(async () => {

            this.getAvisos()

        }, 60000)
    },


    methods: {

        msgNotification(texto, tipo, tempo) {
            this.$toast.open({
                message: texto,
                type: tipo,
                position: 'top-right',
                duration: tempo
                // all of other options may go here
            })
        },

        getUserNome() {
            const authLocal = localStorage.getItem('user')
            const auth = JSON.parse(authLocal)
            this.userName = auth.nome
        },

        getColonias() {
            
            this.colonias = this.$store.state.colonias  

            if (this.colonias.length == 1) {
                this.setColonia(this.colonias[0])
            }
        },

        async setColonia(colonia){
            // console.log('colonia', colonia)
            this.coloniaNome = colonia
            this.$store.commit("setColonia", colonia);

            this.menuVisivel = true

            // const user = this.$store.state.auth.user 

            this.$router.push({ name: 'home' })
            await new Promise(r => setTimeout(r, 50));
            // console.log('aquiiu')
            this.$router.push({ name: 'dashboard' })
        },

        signOut() {
            // console.log('swdwde')
            // localStorage.removeItem('user')
            // localStorage.setItem('path', '/neutro')
            

            this.$store.commit('auth/toggleMenu', false)
            this.$store.commit('auth/setUser', null)
            this.$store.commit("setColonias", []);
            this.$store.commit("setColonia", null);

            this.$router.replace({path: '/'})

            localStorage.clear()
            window.location.reload(true)
        },


        


        async toDashboard() {


            this.$router.push({ name: 'home' })
            await new Promise(r => setTimeout(r, 50));
            this.$router.push({ name: 'dashboard' })

            // if (this.sedes.length > 1) {
            //     this.$router.push({ name: 'home' })
            //     await new Promise(r => setTimeout(r, 50));
            //     this.$router.push({ name: 'dashboardContas' })
            // } else {
            //     this.$router.push({ name: 'home' })
            //     await new Promise(r => setTimeout(r, 50));
            //     this.$router.push({ name: 'dashboard' })
            // }
        },

        getAvisos() {
            api.get(`avisos`)
            .then(async (res) => {
                
                this.avisos = res.data
                localStorage.setItem('avisos', JSON.stringify(res.data))

                this.countAvisos = this.avisos.filter(item => item.classCSSCorCard == 'card-nao-lido' ).length

                const pathURL = this.$route.path
                if (this.countAvisos > 0 && pathURL == '/avisos') {
                    this.$router.push({ name: 'home' })
                    await new Promise(r => setTimeout(r, 50));
                    this.$router.push({ name: 'dashboard' })   
                }
            })
            .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) })   
        },





        toEmpresasConvenio() {
            acessoRestrito(10)
            this.$router.push({ name: 'EmpresasConvenio' })   
        },

        toPeriodos() {
            acessoRestrito(20)
            this.$router.push({ name: 'Periodos' })   
        },

        toOcorrencias() {
            // acessoRestrito(1030)
            this.$router.push({ name: 'Ocorrencias' })   
        },

        toHistoricos() {
            // acessoRestrito(1030)
            this.$router.push({ name: 'Historicos' })   
        },

        toQuartos() {
            acessoRestrito(1)
            this.$router.push({ name: 'Quartos' })   
        },


        toCreditosCartoes() {
            acessoRestrito(100)
            this.$router.push({ name: 'Bar' })   
        },

        toProdutos() {
            acessoRestrito(35)
            this.$router.push({ name: 'Produtos' }) 
        },

        toRelatoriosBar() {
            acessoRestrito(120)
            this.$router.push({ name: 'RelatoriosBar' }) 
        },


        toReservas() {
            acessoRestrito(200)
            this.$router.push({ name: 'Reservas' })   
            // this.$router.push({ name: 'Reservas', params: { codReserva: 4585 } })   
        },

        toConsultarSocio() {
            acessoRestrito(500)
            this.$router.push({ name: 'ConsultarSocio' })   
        },

        toConsultarDisponibilidade() {
            // acessoRestrito(1060)
            this.$router.push({ name: 'ConsultarDisponibilidade' })   
        },

        toAtualizaSociosMensalidades() {


            api.put(`atualiza-socios`)
            .then(() => {
                this.msgNotification('Em breve os dados cadastrais e as mensalidades estarão atualizadas.', 'info', 10000)
            })
            .catch((err) => { this.msgNotification(err.response.data, 'error', 5000) }) 

        },

        toExibirAvisos() {
            this.countAvisos = 0
            this.$router.push({ name: 'avisos' })
        },


        alterarSenha() {
            this.$router.push({ name: 'alterarSenha' })   
        },

        gerenciarUsuarios() {
            this.$router.push({ name: 'gerenciarUsuarios' }) 
        },

        toAjuda() {
            this.$router.push({ name: 'ajuda' }) 
        }




        


    },

    mounted() {
        this.getUserNome()  
    }


};
</script>

<style scoped>

    img {
        height: 40px;
        margin-right: 7px;
        cursor: pointer;
    }

    .topo {
        background: #8f23d6;
    }

    /* .nome-empresa {
        font-weight: bold;
        margin-right: 50px;
        cursor: pointer;
    } */

    .nome-empresa {
        font-weight: bold;
        font-size: 20px;
        color: white;
        /* padding-top: 10px; */
        /* margin-top: 10px; */
        margin-right: 15px;
        cursor: pointer;
        text-decoration: none;
    }

    .item-drop {
        margin-right: 20px;
        /* font-size: 23; */
    }

    .nav-link-sel {
        font-weight: bold;
    }

    .menu-colonia {
        margin-top: 2px;
        margin-left: 0px;
        margin-right: 20px;
        font-weight: bold;
        font-size: 17px;
    }

    .notificacoes {
        margin-right: 50px;
        height: 40px;
        width: auto;
        display: flex;
        gap: 20px;
        cursor: pointer;
        /* background-color: aqua;    */
    }

    .balao-notificacao {
        display: flex;
        display: -webkit-flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 40px;
        width: 40px;
        background-color: #ab4beb;
        border-radius: 50px;
    }

    .icone {
        color: white;
        height: 22px;
        width: 22px;
    }

    .quantia-notificacao {
        position: absolute;   
        margin-bottom: 30px; 
        margin-left: 30px; 
    }

</style>