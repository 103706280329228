<template>
  <div>
  

    <b-button class="button-aplicar" variant="success" block @click="gerarPdf()">Instruções</b-button>  


  </div>
</template>



<script>
import { acessoRestrito } from "@/global";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";

export default {
  name: "BotaoInstrucoes",


  data() {
    return {

      load: false,
      colonia: null

    }
  },


  created() {

    this.colonia = this.$store.state.colonia 
  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    
    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },



    async gerarPdf() {

      acessoRestrito(251)


      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(25).setFont(undefined, 'normal'); 
      doc.text('Srs. Hóspedes', width/2, 20, { align: 'center' })
      doc.text('Ajude-nos a conservar e cuidar do que é seu.', width/2, 33, { align: 'center' })

      doc.setFontSize(15).setFont(undefined, 'bold'); 
      doc.text('•  Entrada na colônia à partir das 08:00 hrs. da manhã.', 14, 52)

      doc.setFontSize(15).setFont(undefined, 'normal'); 
      doc.text('   Lembramos que o valor da estadia deverá ser pago somente em dinheiro,', 14, 62)
      doc.text('   cheque ou através de PIX CNPJ 43152222000132 no momento do Check-in.', 14, 68)
      doc.text('   Não trabalhamos com cartão de débito ou crédito.', 14, 75)


      doc.setFontSize(15).setFont(undefined, 'bold'); 
      doc.text('•  Horário das refeições:', 14, 92)
      doc.text('   Café da manhã das 08:00 às 09:30;', 14, 100)
      doc.text('   Almoço das 12:00 às 13:30;', 14, 108)
      doc.text('   Jantar das 18:30 às 19:30.', 14, 116)


      var img = new Image()
      img.src = this.getImgUrl('pix') 
      doc.addImage(img, 'png', 160, 75, 30, 44) 

      
      doc.setFontSize(15).setFont(undefined, 'normal'); 
      doc.text('•  Ao usar os serviços de bar, será fornecido um cartão, no qual o hóspede', 14, 130)
      doc.text('   fará um crédito a seu critério. No momento do Check-out o valor não utilizado', 14, 137)
      doc.text('   será reembolsado e em caso de extravio será cobrada uma taxa de', 14, 144)
      doc.text('   R$10,00.', 14, 152)
      
      doc.text('•  Ao desocupar o apartamento desligue as lâmpadas, feche as janelas, as', 14, 165)
      doc.text('   torneiras e a porta.', 14, 171)

      doc.text('•  No Check-out favor devolver a chave do apartamento juntamente com o', 14, 183)
      doc.text('   cartão do bar e do estacionamento se tiver.', 14, 189)

      doc.text('•  À colônia não é responsável por objetos deixados na área externa.', 14, 203)

      doc.text('•  Não nos responsabilizamos por danos e furtos de veículos ou objetos', 14, 216)
      doc.text('   de seu interior.', 14, 222)

      


      doc.setFontSize(15).setFont(undefined, 'bold');       
      let paragraph = `* Caso não compareça à Colônia nos dias programados, não haverá devolução dos valores pagos no ato da reserva, salvo por motivo de força maior, devidamente comunicado e justificado por escrito, que se aprovado pela Diretoria do Sindicato será transformado em crédito a ser utilizado no `
      paragraph += `ANO VIGENTE DA RESERVA.`

      const lines = doc.splitTextToSize(paragraph, (180));
      doc.text(lines, 14 , 240, {maxWidth: 180, align: 'justify'});

      if (this.colonia == 'Chico Amaro')
      doc.text('Telefone Colônia: (13) 99632-5544', 14 , 285);
      //------------------------------------------------------------------
                              



      doc.save('Instruções.pdf')
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>