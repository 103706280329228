<template>
  <div id="page-contas">
    <b-list-group>

      <div>
        <h2>Gerenciar usuários</h2>

        <b-row align-h="between">
            
            <button class="button-cadastrar" @click="showModalAdd">Cadastrar</button>
            <!-- <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button> -->

            <div class="form-group col-lg-4">
                <label><span class="text-danger"></span> Buscar</label>
                <input class="form-control"  v-model="filtro" />
            </div>

        </b-row>
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="usuarios"
          :fields="fieldsUsuarios"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>



          <template #cell(Editar)="data">
            <b-icon class="cursor" icon="shield-lock" variant="success" style="width: 23px; height: 23px;" @click="editar(data.item)"></b-icon>
          </template>

          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>
        </b-table>
      </div>

    </b-list-group>


    


    <!-- MODAL CADASTRO -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Usuário" size="lg">
        <div class="row text-left col-lg-12">

          <div class="form-group col-lg-6">
            <label>Nome</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="usuario.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-6">
            <label>E-mail</label>
            <b-form-input
              id="input-1"
              class="text-lowercase"
              v-model="usuario.email"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

          <div class="form-group col-lg-3">
            <b-form-group label="Local de atuação" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
              <b-input-group>
                <b-form-select
                  v-model="usuario.local"
                  :options="locais"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="form-group col-lg-3">
            <b-form-group label="Sede" label-for="bg-opacity" label-cols-sm="4" label-cols-lg="12">
              <b-input-group>
                <b-form-select
                  v-model="usuario.idSede"
                  :options="listaSedes"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="form-group col-lg-6">
            <label class="label-colonia">Acesso a Colônia</label>
            <b-form-checkbox-group
              v-model="usuario.coloniaAcess"
              :options="optionsColonias"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-checkbox-group>
            <!-- <div class="mt-3">Selected: <strong>{{ usuario.coloniaAcess }}</strong></div> -->
          </div>  

          <div class="divider"></div>

          <div class="form-group col-lg-6">
            <b-form-group label="Credencial" class="text-danger" label-cols-sm="4" label-cols-lg="12">
              <b-input-group>
                <b-form-select
                  v-model="credencial"
                  :options="listaCredenciais"
                  @change="atualizaListaPermissoes($event)"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="col-lg-12">
            <div class="form-row">
              <b-form-group class="mx-3" label><span class="text-danger">Permissões de acesso</span>
                <b-form-checkbox-group class="lista-permissoes" v-model="usuario.permissoes" :options="listaPermissoes" switches stacked></b-form-checkbox-group>
              </b-form-group>
              <div class="risco"></div>
            </div>
          </div>

          <!-- <b-card
            class="mt-3"
            header="Resultado Vacinação"
          >
          <pre class="m-0">{{ usuario.sedes }}</pre>
          </b-card> -->

        </div>



        <b-container class="bv-example-row">
          <b-row align-h="around">
            <b-button class="mt-3 botao-confirmar btnAddHospede" variant="success" block @click="save">Confirmar</b-button>
            <b-button class="mt-3 botao-confirmar btnAddHospede" variant="danger" block @click="$refs['modal-add'].hide()">Cancelar</b-button>
          </b-row>
        </b-container>

      </b-modal>
    </div>
    <!-- MODAL CADASTRAR FIM-->
    

    <!-- MODAL APAGAR -->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a exclusão?</h3>
        </div>
        <b-button class="mt-3" variant="outline-success" block @click="apagar"
          >Sim</b-button
        >
        <b-button
          class="mt-2"
          variant="outline-danger"
          block
          @click="$refs['modal-del'].hide()"
          >Não</b-button
        >
      </b-modal>
    </div>
    <!-- MODAL APAGAR FIM-->

    

  </div>
</template>

<script>
import api from "../../services/api";
// import { msg, acessoRestrito } from "@/global";

export default {

  data() {
    return {
      dadosHeader: {},
      filtro: '',

      usuario: {
        id: null,
        nome: null,
        email: null,
        coloniaAcess: [],
        credenciais: null,
        permissoes: [],
        local: null,
        ativo: null
      },

      usuarios: [],
      fieldsUsuarios: [
        { key: "id", label: "ID", sortable: true },
        { key: 'nome', label: 'Nome', sortable: true },
        { key: 'email', label: 'E-mail / Login', sortable: true },
        { key: 'coloniaAcess', label: 'Colônias', sortable: true,  formatter: value => { return value.toString() }},
        { key: 'credenciais', label: 'Credênciais', sortable: true },
        { key: 'local', label: 'Local', sortable: true },
        { key: 'sede', label: 'Sede', sortable: true },
        { key: "Editar", label: "Editar / Permissões", thClass: 'text-center', tdClass: 'text-center' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' }
      ],


      optionsColonias: [
        'Chico Amaro',
        'Guarino Fernandes'
      ],

      locais: [
        'Central',
        'Sede',
        'Colônia'
      ],

      credencial: null,
      listaPermissoes: [],
      listaCredenciais: [],
      listaSedes: [],

      load: true,
    };
  },



  created() {
    this.getUsuarios();
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    getUsuarios() {
      // acessoRestrito(9000)
      this.load = true;

      api.get('admin/usuarios')
      .then((res) => {
        this.load = false;
        this.usuarios = res.data.usuarios
        this.listaSedes = res.data.listaSedes
        this.listaPermissoes = res.data.listaPermissoes
        this.listaCredenciais = res.data.listaCredenciais
      })
      .catch((err) => {
        this.load = false;
        this.msgNotification(err.response.data, 'error', 5000)
      })

    },

    atualizaListaPermissoes(permissoes) {

      this.usuario.credenciais = permissoes.texto
      this.usuario.permissoes = permissoes.ids
    },


    showModalAdd() {
      // acessoRestrito(9001)
      this.resetUsuario()
      this.$refs["modal-add"].show()
    },

    editar(usuario) {
      // acessoRestrito(9002)

      const credencialExiste = this.listaCredenciais.filter(item => item.text == usuario.credenciais)
      this.credencial = credencialExiste.length > 0 ? credencialExiste[0].value : null

      this.usuario = {...usuario}
      this.$refs["modal-add"].show()
    },

    save() {

      
      api.post("admin/usuario", this.usuario)
      .then((res) => {
        this.$refs["modal-add"].hide()
        this.msgNotification(res.data, 'success', 5000)
        this.resetUsuario()
        this.getUsuarios()
      })
      .catch((err) => {
        const corAlerta = err.response.status == 400 ? 'warning' : 'error'
        this.msgNotification(err.response.data, corAlerta, 5000)
      })
      
    },


    showModalApagar(item) {
      // acessoRestrito(9003)

      this.$refs["modal-del"].show();
      this.usuario = item
    },

    apagar() {

      this.$refs["modal-del"].hide()

      api.delete(`admin/usuario/${this.usuario.id}`)
      .then(() => {
        this.msgNotification('Usuário removido.', 'success', 5000)
        this.getUsuarios()
        this.resetUsuario()
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000))

    },

    
    resetUsuario() {

      const usuario = this.usuario
      
      // this.filtroNomeSocio = null
      // this.socioSituacaoNoCadastro = null
      
      for (var proper in usuario){
        
        if (proper == 'coloniaAcess' || proper == 'permissoes') {
          usuario[proper] = []
        }
        else {
          usuario[proper] = null
        }
      }
      
    },


  },

  computed: {

    filtrar() {

      // console.log('opaa')
      
      if (this.filtro.trim() == '') return this.items
          
      let listaFiltrada = []
      this.items.forEach((item) => {
          if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
          if(item.saldo && item.saldo.toString().match(this.filtro)) listaFiltrada.push(item) 
      })

      return listaFiltrada
    },
  },

  
};
</script>

<style scoped>


.divider {
  margin-top: 0px;
  margin-bottom: 25px;
  height: 2px;
  width: 100%;
  background-color: #8f23d6;
}

.button-cadastrar {
  margin-top: 30px;
  margin-left: 15px;
  height: 40px;
  width: 190px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.btnRight {
  /* float: right; */
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-contas h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

.cursor {
  cursor: pointer; 
}

.lista-permissoes {
  margin-top: 10px;
  margin-bottom: 15x;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.text-dados {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.75rem;
}

.label-colonia{
  margin-top: 8px;
}

.botao-confirmar {
  width: 45%; 
  /* width: 170px;  */
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
    .text-dados {
        display: block;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 24px;
        /* font-size: 1.75rem; */
    }
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

}
</style>