<template>
  <div>
  

    <div class="tabelaHospedes" v-show="false">
        <b-table id="tabelaHospedes" striped hover :items="reserva.hospedes" :fields="fieldsHospedes"></b-table>
    </div>

    <div class="tabelaValores" v-show="false">
        <b-table id="tabelaValores" striped hover :items="valores" :fields="fieldsValores"></b-table>
    </div> 

    <!-- <div class="form-group col-lg-4"> -->
      <b-button class="button-aplicar" variant="success" block @click="gerarPdf()">Reserva</b-button>
    <!-- </div> -->


  </div>
</template>

<script>
import { acessoRestrito } from "@/global";
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";
import dayjs from 'dayjs';

export default {
  name: "BotaoReserva",
  props: {
    reserva: {}
  },
  

  data() {
    return {

      load: false,
      dataHoje: null,
      dataIn: null,
      dataOut: null,
      observacao: null,

      fieldsHospedes: [
        {key: 'nome', label: 'Nome'},
        {key: 'idade', label: 'Idade'},
        {key: 'parentesco', label: 'Parentesco'},
        {key: 'quantDiasReservados', label: 'Dias'},
        {key: 'valorDiaria', label: 'Diária', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
        {key: 'valorFinal', label: 'Total', formatter: value => { 
          return  value.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  });
        }},
      ],

      valores: [],
      fieldsValores: [
        {key: 'valorTotal', label: 'VALOR TOTAL'},
        {key: 'valorInicial', label: 'VALOR INICIAL'},
        {key: 'valorRestanteInicial', label: 'VALOR COLÔNIA'},
      ],


      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],


    }
  },


  created() {

    // const colonia = this.$store.state.colonia 
    // console.log(this.reserva)

    const user = JSON.parse(localStorage.getItem('user'))

    this.dataIn = dayjs(this.reserva.dataIn).format('DD/MM/YYYY')
    this.dataOut = dayjs(this.reserva.dataOut).format('DD/MM/YYYY')
    
    this.dataHoje = dayjs().format('DD/MM/YYYY')

    const dia = dayjs().format('DD')
    const mes = dayjs().format('MM')
    const mesTexto = this.meses.filter(item => item.value == mes)[0].text
    const ano = dayjs().format('YYYY')
    this.dataHoje = `${user.sede}, ${dia} de ${mesTexto} de ${ano}`



    // Observações
    //-------------------------------------------------------------------
    const observacao = this.reserva.comentarios.filter(item => item.privacidade == 'Público')
    this.observacao = observacao.length > 0 ? observacao[0].comentario : ''
    // console.log(this.observacao)
    //-------------------------------------------------------------------

    // const valorOutro = 
    // (this.reserva.valorTotal - this.reserva.valorCreditado) - this.reserva.valorSinal
    // console.log(this.reserva)

    var valor = 0
    if (this.reserva.gratuidade == 'CORTESIA') {
      valor = Number(0)
    }
    else
    if (this.reserva.valorCreditado > 0) {
      valor = this.reserva.valorTotal - this.reserva.valorSinal
    }
    else {
      valor = this.reserva.valorTotal - this.reserva.valorSinal    
    }

    
    this.valores = [{ 
      valorTotal: this.reserva.valorTotal.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  }),
      valorInicial: this.reserva.valorCreditado > 0 
                  ? this.reserva.valorSinal.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  })+' (Crédito)'
                  : this.reserva.valorSinal.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  }),
      valorRestanteInicial: valor.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                  }),
    }]

  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },
    

    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {

      acessoRestrito(250)


      const colonia = this.$store.state.colonia
      const coloniaTexto = colonia == 'Chico Amaro'
      ? 'Colonia de Férias Chico Amaro - Itanhaém - SP'
      : 'Colonia de Férias Guarino Fernances - Presidênte Epitácio - SP'


      // const user = JSON.parse(localStorage.getItem('user'))



      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 27, 27) 

      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text('Sede Central - Osasco - SP', width/2, 28, { align: 'center' })
      
      doc.setFontSize(8).setFont(undefined, 'bold'); 
      doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 34, { align: 'center' })

      doc.setDrawColor(0, 0, 0);
      doc.line(0, 36, 500, 36);


      doc.setFontSize(14);          
      doc.text('RESERVA', width/2, 46, { align: 'center' })

      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text(coloniaTexto, width/2, 52, { align: 'center' })
      //------------------------------------------------------------------
                              


      doc.autoTable({
        body: [
          [
            {
              content: `Nº RESERVA: ${this.reserva.cod}`,
              styles: {
                valign: "middle",
                halign: "center",
                fillColor: [244, 247, 249],
                fontStyle: "normal"
              }
            },
            {
              content: `CADEIRANTE: ${this.reserva.deficiente}`,
              styles: { halign: "center", fillColor: [244, 247, 249] }
            },
            {
              content: `SEDE: ${this.reserva.sede}`,
              styles: { halign: "center", fillColor: [244, 247, 249] }
            },
            {
              content: `EMPRESA: ${this.reserva.empresa}`,
              styles: { halign: "center", fillColor: [244, 247, 249] }
            },
            
          ]
        ],
        styles: {
          lineColor: [73, 138, 159],
          lineWidth: 0.2
        },
        theme: "plain",
        startY: 60
      });


      doc.autoTable({
        body: [

          [
            {
              content: `NOME: ${this.reserva.nome}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `CPF: ${this.reserva.cpf}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `FONE: ${this.reserva.fone}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            
          ],
          // [
          //   {
          //     content: `ENDEREÇO: ${this.reserva.endereco}`,
          //     styles: { halign: "left", fillColor: [244, 247, 249] }
          //   },
          //   {
          //     content: `BAIRRO: ${this.reserva.bairro}`,
          //     styles: { halign: "left", fillColor: [244, 247, 249] }
          //   },
            
          // ]

        ],
        styles: {
          lineColor: [73, 138, 159],
          lineWidth: 0.2
        },
        theme: "plain",
        startY: 70
      });



      // doc.autoTable({
      //   body: [

      //     [
      //       {
      //         content: `CIDADE: ${this.reserva.cidade}`,
      //         styles: { halign: "left", fillColor: [244, 247, 249] }
      //       },
      //       {
      //         content: `CEP: ${this.reserva.cep}`,
      //         styles: { halign: "left", fillColor: [244, 247, 249] }
      //       },
      //       {
      //         content: `FONE: ${this.reserva.fone}`,
      //         styles: { halign: "left", fillColor: [244, 247, 249] }
      //       },
      //     ],


      //   ],
      //   styles: {
      //     lineColor: [73, 138, 159],
      //     lineWidth: 0.2
      //   },
      //   theme: "plain",
      //   startY: 85
      // });


      doc.autoTable({
        body: [

          [
            {
              content: `PERÍODO: ${this.dataIn} ATÉ ${this.dataOut}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `DIAS: ${this.reserva.qtdDias}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `SÓCIOS: ${this.reserva.qtdSocios}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `ACOMP.: ${this.reserva.qtdAcomp}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `PET: ${this.reserva.qtdPet}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            {
              content: `QUARTO: ${this.reserva.quartos}`,
              styles: { halign: "left", fillColor: [244, 247, 249] }
            },
            
          ],


        ],
        styles: {
          lineColor: [73, 138, 159],
          lineWidth: 0.2
        },
        theme: "plain",
        startY: 83
      });



      // Tabela HOSPEDES
      //------------------------------------------------------------------
      doc.autoTable({
          html: '#tabelaHospedes',
          tableHegth: height + 20,
          margin: {top: 49, left: 14}, 
          // styles: { fillColor: "#43a047" },
          // headStyles: {fillColor : [124, 95, 240]}, 
          // alternateRowStyles: {fillColor : [231, 215, 252]}, 
          tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


          didDrawPage: function (data){
              data.settings.margin.top = 9;
              // data.settings.margin.left = 9;
          },
          theme: 'grid',
          columnStyles: {
              0: {cellWidth: 63},
              1: {cellWidth: 13},                   
              2: {cellWidth: 35},                   
              3: {cellWidth: 15},                   
              4: {cellWidth: 28},                   
              5: {cellWidth: 28},                   
          }
      }); 

      var finalY = doc.lastAutoTable.finalY;
      // doc.setFontSize(10);
      // doc.text((width - 50), (finalY + 5), this.itemsConsolidacoes[1].valor);
      //------------------------------------------------------------------


      // Tabela VALORES
      //------------------------------------------------------------------
      doc.autoTable({
          html: '#tabelaValores',
          tableHegth: height + 20,
          margin: {top: (finalY + 5), left: 14}, 
          styles: { halign: "center" },
          // styles: { fillColor: "#43a047", halign: "center", valign: "center" },
          // headStyles: {fillColor : [124, 95, 240]}, 
          // alternateRowStyles: {fillColor : [231, 215, 252]}, 
          tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


          didDrawPage: function (data){
              data.settings.margin.top = 9;
              // data.settings.margin.left = 9;
          },
          theme: 'grid'
      }); 
      //------------------------------------------------------------------




      // OBSERVAÇÕES
      //-----------------------------------------------------------------
      doc.setFontSize(9).setFont(undefined, 'bold');     
      doc.text(14, (height - 66), 'OBSERVAÇÕES');   
      doc.setFillColor(0,0,0)
      doc.rect(14,(height - 65),155,17)

      doc.setFontSize(9).setFont(undefined, 'normal');     
      // doc.text(16, (height - 61), 'chegada dia 14 apos as 22 hrs');   
      const lines = doc.splitTextToSize(this.observacao, (150));
      doc.text(lines, 16 , (height - 61), {maxWidth: 150, maxHeight: 10, align: 'justify'});
      //-----------------------------------------------------------------

      // ASSINATURAS
      //-----------------------------------------------------------------
      doc.setFontSize(9).setFont(undefined, 'bold');     
      doc.text(14, (height - 66), 'OBSERVAÇÕES');   
      doc.setFillColor(0,0,0)
      doc.rect(14,(height - 45),183,31)
      // doc.setTextColor(255,255,255)


      doc.setFontSize(8).setFont(undefined, 'bold');     
      doc.text(50, (height - 40), 'EMITENTE'); 
      doc.text(130, (height - 40), 'ASSINATURA DO RESPONSÁVEL'); 

      doc.setFontSize(11).setFont(undefined, 'bold');     
      doc.text(125, (height - 33), this.dataHoje); 

      doc.setDrawColor(0, 0, 0);
      doc.line(105, (height - 20), 197, (height - 20));

      doc.setFontSize(10).setFont(undefined, 'normal');     
      doc.text(57, (height - 16), this.reserva.userCreate, {maxWidth: 70, align: 'center'});
      doc.text(150, (height - 16), this.reserva.nome, {maxWidth: 80, align: 'center'});
      //-----------------------------------------------------------------
      

      // doc.addPage() 



      // Rodapé
      //------------------------------------------------------------------
      // doc.setFontSize(9);          
      // doc.text(9, (height - 10), 'Usuário: ' + auth.nome);

      // doc.setFontSize(9);
      // doc.text(120, (height - 10), 'Data: ' + dayjs().format('DD/MM/YYYY'));

      // doc.setFontSize(9);
      // doc.text(150, (height - 10), 'Hora: ' + dayjs().format('HH:mm'));

      // var pageCount = doc.internal.getNumberOfPages();
      // for(var i = 0; i < pageCount; i++) { 
      //     doc.setPage(i); 
      //     let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      //     doc.setFontSize(9);
      //     doc.text('Pág ' + pageCurrent + '/' + pageCount, 190, (height - 10));
      // }
      //------------------------------------------------------------------



      doc.barcode(this.reserva.cod.toString(), {
        fontSize: 50,
        textColor: "#000000",
        x: 167.4,
        y: 248.5
      })

      doc.save(`Reserva nº ${this.reserva.cod}.pdf`)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>