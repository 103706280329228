<template> 
  <div>  
    

    <div class="text-center text-warning my-2" v-if="load">
        <b-spinner class="align-middle"></b-spinner>
        <strong></strong>
    </div>
    
    <div class="row col-lg-12" v-else>



      <div class="tabela">
        <b-table
          id="my-table" 
          striped
          hover 
          ref="selectableTable"
          class="text-left" 
          :items="quartos" 
          :fields="fieldsQuartos" 
          :busy="load"
          value="true"
          unchecked-value="false"
          outlined
          >              

          <template #table-busy>
              <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong></strong>
              </div>
          </template>
          


          <template #cell(visao)="data">
            <img src="../../../assets/vista-sol.png" alt="vista" height="30px" v-if="data.item.visao == 'MAR'"/>
            <img src="../../../assets/vista-vegetacao.png" alt="vista" height="30px" v-if="data.item.visao == 'VEGETAÇÃO'"/>
            <img src="../../../assets/vista-cidade.png" alt="vista" height="30px" v-if="data.item.visao == 'CIDADE'"/>
            <img src="../../../assets/vista-cadeirante.jpg" alt="vista" height="30px" v-if="data.item.visao == 'CADEIRANTE'"/>
          </template>


        </b-table>
      </div>


    </div>




  </div> 
</template>
 
<script>
import api from "@/services/api";
  
export default {
  name: 'ModalQuartoInfo', 
  props:{ 
    reserva: {}
  },

  data() {
    return {  

      load: false,

      quartos: [],
      fieldsQuartos: [
        {key: 'id', label: 'Número'},
        {key: 'visao', label: 'Visão'},
        {key: 'andar', label: 'Andar'},
        {key: 'predio', label: 'Prédio'},
        {key: 'solteiro', label: 'Solteiro', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'beliche', label: 'Beliche', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'casal', label: 'Casal', thClass: 'text-center', tdClass: 'text-center'},
        {key: 'totalAcomodacoes', label: 'Total acomodações', thClass: 'text-center', tdClass: 'text-center'},
      ],

    }

  },
  created() { 

    this.getQuartoInfo()
  },
  
  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
          message: texto,
          type: tipo,
          position: 'top-right',
          duration: tempo
          // all of other options may go here
      })
    },



    getQuartoInfo() {

      this.load = true
      
      api.get(`reservas-quarto-info/${this.reserva.colonia}/${this.reserva.quartos}`)
      .then(res => {
        this.load = false
        this.quartos = res.data
      })
      .catch((err) => {
        this.load = false
        this.msgNotification(err.response.data, 'warning', 5000)
      });
    }



  }

    
};

</script>
 
   
 <style scoped>


 .tabela {
    width: 80vw;
    margin-top: 0px;
  }

  .cursor { 
    cursor: pointer;
  }

</style>