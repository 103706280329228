<template>
    <div id="ajuda">

        <!-- <div v-for="video in videos" :key="video.title">
            <video class="video-js" controls preload="auto" width="900px">
                <source v-bind:src="video.src" type="video/mp4">
            </video>
        </div> -->



    </div>
</template>

<script>
// import v1 from "@/assets/videos/dd.mp4";
// import v2 from "@/assets/videos/Marte.mp4";

export default {

  data() {
    return {

        // videos: [
        //     { title: "v1", src: v1, desc: "v1"},
        // ]  
  
    }
  },
}

</script>

<style scoped>

.main {
    height: 500px;
    width: 500px;
}
    
</style>





