<template>
    <div>

        <div v-if="acessoViaMovel">

            <div class="corpo">
                <div class="topo"></div>
                <img src="../assets/logoSind.png" alt="Avatar" class="avatar"> 
                <!-- <img src="../assets/Home.png" alt="Avatar" class="avatar">  -->
    
                <span class="nome">{{ cartao.nome }}</span>
    
                <span class="saldo">{{ cartao.saldo }}</span>
                <b-button class="btn-consulta-saldo" variant="success" block @click="getSaldo()">Atualizar saldo</b-button>
                <!-- <span class="gastros">Gastos: R$ 245,00</span> -->
            </div>
    
    
    
            <div class="tabela">
            <b-table
                id="table-quartos" 
                striped
                hover 
                ref="selectableTable"
                class="text-left" 
                :items="cartao.gastos" 
                :fields="fieldsGastos" 
                :busy="load"
                value="true"
                outlined
                >              
    
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong></strong>
                    </div>
                </template>
    
            </b-table>
            </div>
    
    
    
    
    
            <!-- MODAL INFORMA QUARTO -->
            <div>
            <b-modal ref="modal-informe-quarto" hide-footer title="Informe o número do seu quarto" size="mx">
                
                <div class="form-group col-lg-12">
                <label></label>
                <b-form-input
                    id="input-1"
                    type="text"
                    v-model="quarto"
                ></b-form-input>
                </div>
    
                <b-button variant="success" block @click="confirmarQuarto()">Confirmar quarto</b-button>
                
            </b-modal>
            </div>
            <!-- MODAL INFORMA QUARTO FIM-->
        </div>


        <div class="corpo" v-else>
            <br>
            <!-- <span class="saldo">{{ cartao.saldo }}</span> -->
        </div>




    </div>
</template>


<script>
import api from "../services/api";
// import { msg } from "@/global";
export default {

name: 'ConsultarSaldo',

data() {
  return {

    acessoViaMovel: false,
    cardHash: this.$route.query.card,

    quarto: null,
    cartao: {
        saldo: 'R$ 0,00',
        gastos: []
    },

    fieldsGastos: [
        {key: 'produto', label: 'Produto'},
        {key: 'valor', label: 'Valor'},
        // {key: 'data', label: 'Data'}
    ],


  }
},

created() {


    // Descomentar para valer qrCode
    (screen.width < 1024 || screen.height < 768)
    ? this.acessoViaMovel = true
    : window.location.href = 'https://sorocabana.org.br'
    
},

mounted() {
    // Descomentar para valer qrCode
    this.verificaToken() 
    // this.getNumeroCartao()
},

methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top',
        duration: tempo
        // all of other options may go here
      })
    },


    verificaToken() {

        const info = localStorage.getItem('info')
        // console.log('info',info)
        
        if (!info) {
            // alert('1')
            this.$refs["modal-informe-quarto"].show()
        }
        else {
            // alert('2')
            this.getSaldo()
        }

    },

    confirmarQuarto() {

        const dados = {
            quarto: this.quarto,
            cardHash: this.cardHash
        }

        api.patch('publico/confirma-quarto', dados)
        .then(res => {
            this.$refs["modal-informe-quarto"].hide()
            // console.log(res.data)
            const info = res.data
            localStorage.setItem('info', JSON.stringify(info))
            this.loadSocios = false

            this.getSaldo()
        })
        .catch((err) => {
            this.loadSocios = false
            this.msgNotification(err.response.data, 'info', 4000)
        });
    },

    getSaldo() {

        this.cardHash = this.$route.query.card
        const info = JSON.parse(localStorage.getItem('info'))
        
        api.get(`publico/consulta-saldo?token=${info.token}&cardHash=${this.cardHash}`)
        .then(res => {
            this.cartao = res.data
        })
        .catch((err) => {

            if (err.response.status == 402) {
                // this.msgNotification('Sem permissão para continuar.', 'warning', 5000)
                localStorage.clear()
                this.verificaToken()
                return                
            }

            this.msgNotification(err.response.data, 'info', 4000)
        });
    },

    // Temp
    // getNumeroCartao() {

    //     api.post('publico/consulta-quarto/', { cardHash: this.cardHash })
    //     .then(res => {
    //         // console.log(res.data)
    //         this.cartao.saldo = res.data
    //     })
    //     .catch((err) => {
    //         this.msgNotification(err.response.data, 'info', 4000)
    //     });
    // }

},



};

</script>

<style scoped>

.corpo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.topo {
    width: 100%;
    height: 90px;
    /* background-color: #c3cfe2; */
    background-image: linear-gradient(to left, #c3cfe2, #f5f7fa);
    /* background-image: linear-gradient(to right, #8f23d6, #8e23d6c0); */
}

.avatar {
    /* vertical-align: middle; */
    margin-top: -60px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border-width: 4px; 
    border-style: solid;
    /* border-color:  rgb(248, 246, 246); */
    border-color:  rgb(37, 37, 37);
}

.nome {
    margin-top: 30px;
    font-size: 22px;
    font-weight: bold;
}

.saldo {
    margin-top: 30px;
    font-size: 60px;
    font-weight: bold;
}

.btn-consulta-saldo {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 220px;
}

.tabela {
    width: 95vw;
    margin-top: 15px;
    margin-left: 2vw;
}

    
</style>