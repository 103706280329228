<template>
  <div>
  

    <div class="row text-left col-lg-12">

      <div class="form-group col-lg-4">
        <label><span class="text-danger"></span> Valor atual da diária</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="valorDiariaAtualView"
          readonly
        ></b-form-input>
      </div>

      <div class="form-group col-lg-4">
        <label><span class="text-danger"></span> Novo valor da diária</label>
        <b-form-input
          id="input-1"
          class="text-uppercase"
          v-model="novoValorDiaria"
          placeholder="0,00"
          @keyup="formatMoeda($event)"
        ></b-form-input>
      </div>

      <div class="form-group col-lg-4">
        <b-button class="button-aplicar" variant="success" block @click="confirmarValor()">Confirmar valor</b-button>
      </div>

  </div>
    


  </div>
</template>

<script>

export default {
  name: "ModalFaturarPersonalizado",
  props: {
    hospede: {}
  },

  data() {
    return {

      load: false,

      // hospedeLocal: {},
      valorDiariaAtual: null,
      valorDiariaAtualView: null,
      novoValorDiaria: null,
      novoValorDiariaView: null,


    }
  },


  created() {

    // const colonia = this.$store.state.colonia 
    // console.log(this.hospede)
    // this.hospedeLocal = {...this.hospede}
    this.valorDiariaAtual = this.hospede.valorDiaria
    this.valorDiariaAtualView = this.valorDiariaAtual.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
    });

  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },


    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 2)
          input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

    },


    confirmarValor() {

      if (!this.novoValorDiaria) {
        this.msgNotification('Informe o novo valor da diária.', 'warning', 5000)
        return
      }

      const valorDiaria = this.novoValorDiaria.toString().split(',').join('.')
      this.hospede.valorDiaria = parseFloat(valorDiaria)
      
      const valorFinal = this.hospede.valorDiaria * this.hospede.quantDiasReservados
      this.hospede.valorFinal = parseFloat(valorFinal)
      
      this.$emit('EMITfaturarPersonalizado', this.hospede)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>