<template>
  <div>
  

    <div class="row text-left col-lg-12">

      <div class="form-group col-lg-4">
        <BotaoReserva :reserva="reserva"></BotaoReserva>
      </div>

      <div class="form-group col-lg-4">
        <BotaoInstrucoes></BotaoInstrucoes>
      </div>

      <div class="form-group col-lg-4">
        <BotaoMapaColonia></BotaoMapaColonia>
      </div>




      <div class="form-group col-lg-6">
        <BotaoRecibo :reserva="reserva" :via="'Recibo Sede'"></BotaoRecibo>
      </div>

      <div class="form-group col-lg-6">
        <BotaoRecibo :reserva="reserva" :via="'Recibo Colônia'"></BotaoRecibo>
      </div>

      <!-- <div class="tabelaHospedes" v-show="false">
          <b-table id="tabelaHospedes" striped hover :items="reserva.hospedes" :fields="fieldsHospedes"></b-table>
      </div>

      <div class="tabelaValores" v-show="false">
          <b-table id="tabelaValores" striped hover :items="valores" :fields="fieldsValores"></b-table>
      </div> 

      <div class="form-group col-lg-4">
        <b-button class="button-aplicar" variant="success" block @click="gerarPdf()">Reserva</b-button>
      </div>

      <div class="form-group col-lg-4">
        <b-button class="button-aplicar" variant="success" block @click="doPDF()">Instruções</b-button>
      </div>

      <div class="form-group col-lg-4">
        <b-button class="button-aplicar" variant="success" block @click="doPDF()">Mapa da Colônia</b-button>
      </div> -->

  </div>
    


  </div>
</template>

<script>
import BotaoReserva from './BotaoReserva'
import BotaoInstrucoes from './BotaoInstrucoes'
import BotaoMapaColonia from './BotaoMapaColonia'
import BotaoRecibo from './BotaoRecibo'

export default {
  name: "ModalImprimirReserva",
  props: {
    reserva: {}
  },
  components: { 
    BotaoReserva,
    BotaoInstrucoes,
    BotaoMapaColonia,
    BotaoRecibo
  },

  data() {
    return {

      load: false,

    }
  },


  created() {

    // const colonia = this.$store.state.colonia 
    // console.log(this.reserva)

  },
  

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

  }


}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>